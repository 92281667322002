.title-Projects {
  padding-top: 2rem;
  font-size: 85px;
  color: black;
  text-align: center;
}
p {
  color: black;
}

.card-container {
  display: flex;
  justify-content: center; /* Centers the cards horizontally */
  align-items: center; /* Centers the cards vertically (if the container has a height) */
  flex-wrap: wrap; /* Allows the cards to wrap if they don't fit in one row */
  gap: 16px; /* Optional: Adds space between cards */
  padding: 16px;
}
