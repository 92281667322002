.card-title1 {
  text-align: left;
  font-size: 40px;
  /* margin: 0; */
}
.title-click {
  text-decoration: none;
  color: black;
}
p {
  padding-top: 5px;
}
