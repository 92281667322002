.title-Blog {
  padding-top: 2rem;
  padding-left: 4rem;
  color: black;
  text-align: left;
}
.title-Blog {
  font-size: 85px;
  color: black;
  position: static;
  padding-top: 2rem;
  text-align: center;
}
.date-entries {
  text-align: left;
}
.title-entries {
  padding-top: 4rem;
  text-align: left;
}
.paragraph-text {
  font-size: 1.5rem;
  text-align: justify;
}

.container-expander {
  width: 100vw;
  height: 100vh;
}
