.header {
  position: sticky;
  top: 0px;
  left: 0;
  width: 100%;
  padding: 20px 5%;
  /* background-color: #eee7dc; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  overflow: hidden;
}
.logo {
  font-size: 32px;
  color: #494e52;
  font-weight: 700;
}
.navbar a {
  /* position: relative; */
  float: left;
  display: block;
  font-size: 20px;
  color: #494e52;
  font-weight: 500;
  margin-left: 30px;
}

.navbar a::before {
  content: "";
  position: sticky;
  position: absolute;
  width: 0%;
  top: 100%;
  left: 0;
  height: 2px;
  background: #494e52;
  transition: 0.35s;
}

.navbar a:hover::before {
  width: 100%;
}

@media (max-width: 48rem) {
  .navbar .hamburger {
    display: block; /* Shows hamburger menu on small screens */
    margin-right: 2rem;
  }
  .navbar {
    position: sticky; /* Changed to 'inherit' to cover full height */
    flex-direction: column;
    position: sticky; /* Changed to 'fixed' to cover full height */
    top: 0px;
    right: -3rem; /* Start from the right edge */
    height: 100%; /* Full height */
    width: 60%; /* Full width to cover the screen */
    display: flex; /* Updated to flex to enable visibility during transition */
    flex-direction: column;
    justify-content: center;
    background-color: var(#494e52);
    transform: translateX(100%); /* Start off-screen to the right */
    transition: transform 0.3s ease; /* Smooth transition for sliding in */
  }
  .navbar.open {
    transform: translateX(
      0%
    ); /* Slide in to cover the right 80% of the screen */
  }

  .navbar a {
    padding: 0.5rem; /* Padding using rem */
    width: 100%; /* Full width for each link */
    text-align: center;
    box-sizing: border-box;
  }
}
