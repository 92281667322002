.title-experience {
  padding-top: 2rem;
  padding-left: 4rem;
  color: black;
  text-align: left;
}
.title-experience-test {
  color: black;
  text-align: right;
}
.title-main {
  font-size: 70px;
  color: black;
  position: static;
  padding-top: 2rem;
  text-align: center;
}
.text-experience {
  padding-left: 7.5rem;
  font-size: 17px;
  padding-right: 7.5%;
}
.subtitle-experience {
  padding-left: 4rem;
  text-align: left;
  font-style: italic;
  font-weight: 500;
}
.education-test {
  text-align: left;
  padding: 5rem;
  padding-bottom: 0rem;
  padding-top: 2rem;
}
.education-sub {
  text-align: left;
  font-weight: 500;
  padding: 5rem;
  padding-bottom: 0rem;
  padding-top: 0rem;
}

@media (max-width: 48rem) {
  .title-main {
    font-size: 40px;
    /* background-color: blue; */
    text-align: center;
  }
  .title-experience {
    font-size: 20px;
    /* background-color: red; */
    text-align: left;
    padding-left: 0;
  }
  .subtitle-experience {
    font-size: 1rem;
    /* background-color: pink; */
    text-align: left;
    padding-left: 0;
  }
  .text-experience {
    padding-left: 10%;
    text-align: justify;
    padding-right: 10%;
  }
}
