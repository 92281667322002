.cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px;
  padding-right: 4.5rem;
}
.text-aboutme {
  text-align: left;
  font-size: 25px;
  color: #494e52;
  padding-left: 1rem;
  padding-bottom: 4rem;
  padding-right: 1rem;
}

.mini-title1 {
  text-align: left;
  font-size: 45px;
  color: #494e52;
  padding-top: 1.5rem;
  padding-left: 1rem;
}
@media (max-width: 80rem) {
  .cont {
    margin: 10px;
  }
  .center-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
  }
  .mini-title1 {
    text-align: left;
    font-size: 35px;
    color: #494e52;
  }
  .text-aboutme {
    /* Add any specific styles for the text */
    text-align: left;
    font-size: 20px;
    color: #494e52;
    /* margin-bottom: 1rem; Space between text and card */
  }
  .card {
    flex: 0 0 auto;
    border: 1px solid #494e52;
    border-radius: 65px;
    box-shadow: 5px 5px 5px;
    padding: 20px;
    max-width: 33rem;
    margin: 10px;
    color: #494e52;
    width: 22rem; /* Set the desired width */
  }
  .card .card-picture {
    max-width: 60%;
    height: auto;
    margin-bottom: 5px;
  }
  .card .card-title {
    font-size: 30px;
    text-align: center;
    color: #494e52;
  }
  .card .card-text {
    text-align: center;
    font-size: 20px;
    color: #494e52;
  }
  .github {
    color: black;
    /* margin: 0.6rem; */
    /* size: 42px; */
  }
  .linkedin {
    /* margin: 0.6rem; */
    color: #0077b5;
  }
}

@media (max-width: 55rem) {
  .cont {
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
  }
  .center-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4.5rem;
  }
  .mini-title1 {
    text-align: left;
    font-size: 30px;
    color: #494e52;
  }
  .text-aboutme {
    /* Add any specific styles for the text */
    text-align: left;
    font-size: 17px;
    color: #494e52;
    /* margin-bottom: 1rem; Space between text and card */
  }
  .card {
    flex: 0 0 auto;
    border: 1px solid #494e52;
    border-radius: 65px;
    box-shadow: 5px 5px 5px;
    padding: 20px;
    max-width: 33rem;
    margin: 10px;
    color: #494e52;
    width: 22rem; /* Set the desired width */
  }
  .card .card-picture {
    max-width: 60%;
    height: auto;
    margin-bottom: 5px;
  }
  .card .card-title {
    font-size: 30px;
    text-align: center;
    color: #494e52;
  }
  .card .card-text {
    text-align: center;
    font-size: 20px;
    color: #494e52;
  }
  .github {
    color: black;
    /* margin: 0.6rem; */
    /* size: 42px; */
  }
  .linkedin {
    /* margin: 0.6rem; */
    color: #0077b5;
  }
}
