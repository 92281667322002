.card {
  /* float: right; */
  flex: 0 0 40rem;
  border: 1px solid #494e52;
  border-radius: 65px;
  box-shadow: 5px 5px 5px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  width: 30rem;
  color: #494e52;
  display: inline-block;
}
.card .card-picture {
  max-width: 60%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 5px;
}
.card .card-title {
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #494e52;
}
.card .card-text {
  text-align: center;
  font-size: 20px;
  color: #494e52;
}

.github {
  color: black;
  margin: 0.6rem;
  /* size: 42px; */
}
.linkedin {
  margin: 0.6rem;
  color: #0077b5;
}
