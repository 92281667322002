.title-Resources {
  padding-top: 2rem;
  font-size: 85px;
  color: black;
  text-align: center;
}
p {
  color: black;
}

.container-expander1 {
  width: 100vw;
  height: 100vh;
}
