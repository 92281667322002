.container-text {
  background-color: #d3d4dac0;
  padding: 20px;
  border-radius: 5px;
  width: 93%;
  height: 100%;
}

/* This is only for centering items */
.center {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.container {
  display: flex;
  padding-top: 2%;
  justify-content: center;
  align-items: baseline;
  height: 100vh;
}
.divider {
  border: none;
  border-top: 4px solid black;
  margin: 10px auto;
  width: 93%;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 20px;
  padding: 1.5%;
}
ul {
  padding-left: 2%;
}

button {
  padding: 10px 20px;
  font-size: 20px;
  background-color: #f6f6f6;
  border-radius: 10px;
  border: 0;
}
.title-p {
  font-size: 60px;
}
.subtitle-p {
  font-size: 35px;
}
.link {
  text-decoration: none;
  color: black;
}
