.main {
  height: 100vh;
  width: 100vw;
}
@media (max-width: 55rem) {
  .main {
    height: 100vh;
    width: 100vw;
    /* You can add specific styles for iPhone Pro Max here */
  }
}
